
import React from 'react';
import Home from './Home';





import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => (
    <Router>
            <Routes>
                <Route path="/" exact element={<Home />} />
            </Routes>
    </Router>
);

export default App;

//<Redirect to='${/:navigator.language}' />
