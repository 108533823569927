import React from "react";
import logo from './assets/logo.webp';
import instaIcon from './assets/instaIcon.webp';
import mailIcon from './assets/mailIcon.webp';
import picPhoneEN from './assets/picPhoneEN.webp';
import picPhoneFR from './assets/picPhoneFR.webp';
import dlAndroid from './assets/dlAndroid.webp';
import dlApple from './assets/dlApple.webp';
import androidLogo from './assets/androidLogo.png';
import ApplLogo from './assets/ApplLogo.png';
import DownloadButton from './DownloadButton'
import { library } from '@fortawesome/fontawesome-svg-core'
import { useMediaQuery } from 'react-responsive'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import LocalizedStrings from 'react-localization';
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./App.css";

SwiperCore.use([EffectCoverflow, Autoplay, Pagination]);

library.add(fab, faStar)

const useDesktopMediaQuery = () =>
  useMediaQuery({ query: "(min-width: 1280px)" })

const useTabletAndBelowMediaQuery = () =>
  useMediaQuery({ query: "(max-width: 1279px)" })

const Desktop = ({ children }) => {
  const isDesktop = useDesktopMediaQuery()

  return isDesktop ? children : null
}



const TabletAndBelow = ({ children }) => {
  const isTabletAndBelow = useTabletAndBelowMediaQuery()

  return isTabletAndBelow ? children : null
}

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log(userAgent)

  if (/windows phone/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.tame&gl=FR";
  }

  if (/android/i.test(userAgent)) {
    return "https://play.google.com/store/apps/details?id=com.tame&gl=FR";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "https://apps.apple.com/app/tame/id6443941344";
  }

  return "https://play.google.com/store/apps/details?id=com.tame&gl=FR"
}


function App() {
  const fr = strings.language === 'fr'
  const android = getMobileOperatingSystem() === "https://play.google.com/store/apps/details?id=com.tame&gl=FR"
  return (
    <div style={{ overflow: 'hidden', flex: 1 }}>
      <Desktop>
        <div style={{ height: '100%', paddingTop: 'calc(2vh)', background: "linear-gradient(0deg, #A9A0F9, #CCA1FA 70%, #B0A1FA)" }}>
          <div style={{ display: "flex", position: 'fixed', top: 0, right: 0, left: 0, justifyContent: "space-between", alignItems: "center", paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
            <img src={logo} style={{ height: "calc(7vh)" }} alt="Tame Logo" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', paddingTop: 'calc(10vh)' }}>
            <div style={{ paddingRight: 'calc(10vh)', width: 'calc(80vh)', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', paddingBottom: "calc(10vh)", paddingTop: "calc(8vh)", }}>
              <h1 style={{ textAlign: 'right', fontSize: 'calc(14vh)', lineHeight: 'calc(17vh)', paddingBottom: 'calc(2vh)' }}>Swipe less, feel more</h1>
              <DownloadButton />
            </div>
            {fr ? <img src={picPhoneFR} style={{ width: '47%' }} alt="Couple looking at each other" /> :
              <img src={picPhoneEN} style={{ width: '47%' }} alt="Couple looking at each other" />}
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "calc(3vh)", paddingBottom: "calc(9vh)" }}>
            <h2>{strings.reachOut}</h2>
            <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 'calc(2vh)' }}>
              <a href="https://www.instagram.com/tameapp/" >
                <img src={instaIcon} style={{ height: "calc(7vh)", paddingRight: "calc(1vh)" }} alt="Instagram button" />
              </a>
              <a href="mailto:contact@tame-app.com" >
                <img src={mailIcon} style={{ height: "calc(7vh)", paddingLeft: "calc(1vh)" }} alt="Mail button" />
              </a>
            </div>
          </div>
          <div style={{ display: 'flex', width: '95%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: "calc(1vh)" }}>
            <a href="https://docs.google.com/document/d/1L6UP0xpD1TwrxzbTXhoAhIYm207CLTdWCjOMbQXYt0A/edit?usp=sharing">
              <small style={{ paddingLeft: "calc(1.5vh)", paddingRight: "calc(30vh)" }}>{strings.cgu}</small>
            </a>
            <a href="https://docs.google.com/document/d/1xd0R8Pvmk2hmWwvIAzOUx1H71wDrf2tz6cH27mc_UGA/edit?usp=sharing">
              <small>{strings.mentions}</small>
            </a>
            <a href="https://docs.google.com/document/d/1xd0R8Pvmk2hmWwvIAzOUx1H71wDrf2tz6cH27mc_UGA/edit?usp=sharing">
              <small style={{ paddingRight: "calc(1.5vh)", paddingLeft: "calc(30vh)" }}>{strings.charte}</small>
            </a>
          </div>
        </div>
      </Desktop>

      <TabletAndBelow>
        <div style={{ background: "linear-gradient(0deg, #B0A1FA, #CCA1FA 40%, #B0A1FA)" }}>
          <div style={{ display: "flex", position: 'fixed', top: 0, right: 0, left: 0, height: "calc(8vh)", justifyContent: "space-between", alignItems: "center", paddingLeft: 10, paddingRight: 10 }}>
            <img src={logo} style={{ height: "calc(5.5vh)" }} alt="Tame Logo" />
            <a href={`${getMobileOperatingSystem()}`}>
              {android ? <img src={dlAndroid} style={{ height: "calc(5vh)" }} alt="Download Button" /> : <img src={dlApple} style={{ height: "calc(5vh)" }} alt="Download Button" />}
            </a>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: "calc(10vh)" }}>
            <h1 style={{ textAlign: 'center', fontSize: "calc(6.5vh)", lineHeight: "calc(9vh)", width: "calc(33vh)" }}>Swipe less, feel more</h1 >
            {fr ? <img src={picPhoneFR} style={{ width: "95%", marginTop: "calc(4vh)" }} alt="Couple looking at each other" /> :
              <img src={picPhoneEN} style={{ width: "95%", marginTop: "calc(4vh)" }} alt="Couple looking at each other" />}
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 'calc(2vh)' }}>
            <h2>{strings.download}</h2>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {android ? <a style={{ display: 'flex', flexDirection: 'row', width: "calc(40vh)", backgroundColor: '#EBEEFF', height: 'calc(6.5vh)', alignItems: 'center', justifyContent: 'center', marginTop: 'calc(1.5vh)', borderRadius: 'calc(1.5vh)' }} href={`${getMobileOperatingSystem()}`}>
                <img src={androidLogo} style={{ height: "calc(3vh)", marginRight: 'calc(1vh)' }} alt="Android Logo" />
                <h3>Google Play</h3>
              </a> : <a style={{ display: 'flex', flexDirection: 'row', width: "calc(40vh)", backgroundColor: '#EBEEFF', height: 'calc(6.5vh)', alignItems: 'center', justifyContent: 'center', marginTop: 'calc(1.7vh)', borderRadius: 'calc(1.5vh)' }} href={`${getMobileOperatingSystem()}`}>
                <img src={ApplLogo} style={{ height: "calc(3.3vh)", marginRight: 'calc(0.8vh)', marginBottom: 'calc(0.2vh)' }} alt="Apple Logo" />
                <h3>App Store</h3>
              </a>}
            </div>
          </div>
          <div style={{ paddingTop: 'calc(5vh)', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: "calc(5vh)" }}>
            <h2>{strings.reachOut}</h2>
            <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 'calc(3vh)' }}>
              <a href="https://www.instagram.com/tameapp/" >
                <img src={instaIcon} style={{ height: "calc(7vh)", paddingRight: "calc(1vh)" }} alt="Instagram button" />
              </a>
              <a href="mailto:contact@tame-app.com" >
                <img src={mailIcon} style={{ height: "calc(7vh)", paddingLeft: "calc(1vh)" }} alt="Mail button" />
              </a>
            </div>
          </div>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', paddingBottom: "calc(0.5vh)" }}>
            <a href="https://docs.google.com/document/d/1L6UP0xpD1TwrxzbTXhoAhIYm207CLTdWCjOMbQXYt0A/edit?usp=sharing">
              <small style={{ paddingLeft: "calc(1.5vh)" }}>{strings.cgu}</small>
            </a>
            <a href="https://docs.google.com/document/d/1xd0R8Pvmk2hmWwvIAzOUx1H71wDrf2tz6cH27mc_UGA/edit?usp=sharing">
              <small>{strings.mentions}</small>
            </a>
            <a href="https://docs.google.com/document/d/1xd0R8Pvmk2hmWwvIAzOUx1H71wDrf2tz6cH27mc_UGA/edit?usp=sharing">
              <small style={{ paddingRight: "calc(1.5vh)" }}>{strings.charte}</small>
            </a>
          </div>
        </div>
      </TabletAndBelow>
    </div >
  );
}

export default App;

let strings = new LocalizedStrings({
  en: {
    mentions: "Legal information",
    cgu: "Terms",
    charte: "Privacy",
    language: "en",
    reachOut: "Reach out to us!",
    articles: "Why Tame?",
    mission: "Our mission",
    behindTame: "Behind Tame",
    rethinking: "Rethinking technology meant to bring us together",
    read: "Read",
    readMore: "Read more",
    informed: "Let me know when Tame is out!",
    download: "Download now on"

  },
  fr: {
    mentions: "Mentions légales",
    cgu: "CGU",
    charte: "Confidentialité",
    download: "Télécharger",
    taste: "Des profils pour tous les goûts",
    press: "Ils parlent de nous",
    language: "fr",
    reachOut: "Contacte-nous !",
    articles: "Pourquoi Tame ?",
    mission: "Notre mission",
    behindTame: "L'histoire de Tame",
    rethinking: "Réimaginer la technologie qui nous rassemble",
    read: "Lire",
    readMore: "En savoir plus",
    informed: "Informez-moi quand Tame est dans les stores !",
    download: "Disponible sur"
  },
});
