import React from "react";
import LocalizedStrings from 'react-localization';
import androidLogo from './assets/androidLogo.png';
import ApplLogo from './assets/ApplLogo.png';


class DownloadButton extends React.Component {

    state = { isClicked: false, value: "" }
    handleChange = (event) => { this.setState({ value: event.target.value }); }

    render() {
        return (
            <div style={{ display: 'flex', zIndex: 1, flexDirection: 'column', paddingTop: 'calc(3vh)' }}>
                <a style={{ display: 'flex', flexDirection: 'row', width: "calc(40vh)", backgroundColor: '#EBEEFF', height: 'calc(6.5vh)', alignItems: 'center', justifyContent: 'center', marginTop: 'calc(1.5vh)', borderRadius: 'calc(1.5vh)' }} href="https://play.google.com/store/apps/details?id=com.tame&gl">
                    <img src={androidLogo} style={{ height: "calc(3vh)", marginRight: 'calc(1vh)' }} alt="Android Logo" />
                    <h3>{strings.GooglePlay}</h3>
                </a>
                <a style={{ display: 'flex', flexDirection: 'row', width: "calc(40vh)", backgroundColor: '#EBEEFF', height: 'calc(6.5vh)', alignItems: 'center', justifyContent: 'center', marginTop: 'calc(1.7vh)', borderRadius: 'calc(1.5vh)' }} href="https://apps.apple.com/app/tame/id6443941344">
                    <img src={ApplLogo} style={{ height: "calc(3.3vh)", marginRight: 'calc(0.8vh)', marginBottom: 'calc(0.2vh)' }} alt="Apple Logo" />
                    <h3>{strings.AppStore}</h3>
                </a>
            </div>
        )
    }
}

let strings = new LocalizedStrings({
    en: {
        AppStore: "App Store",
        GooglePlay: "Google Play",
    },
    fr: {
        joinExperience: "App Store",
        GooglePlay: "Google Play",
    },
});

export default DownloadButton